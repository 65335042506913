import Auth from 'aws-amplify'

// const awsConfig = {
//     identityPoolId: 'ap-south-1:442d7325-8843-48d8-97b0-4dd4e2a26cea',
//     region: 'AP_SOUTH_1',
//     userPoolId: 'ap-south-1_ygOBIOKUu',
//     userPoolWebClientId: '5asl8o7lmoakat42jptist8hie'
// }



//cognito pool name: qa1-farmingforall
//federated identity pool: qa1_farmingforall
const awsConfig = {
    Auth : {
        identityPoolId: 'ap-south-1:d21fed49-91c9-4ccb-be69-d570ad52f35b', 
        region: 'AP_SOUTH_1',
        userPoolId: 'ap-south-1_qTXDafsng',
        userPoolWebClientId: '5oik3tuj0ki1f7dvh1h6j3bk72',
        oauth: {
            domain: 'qa-farmingforall.auth.ap-south-1.amazoncognito.com/',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'exp://127.0.0.1:19000/',
            redirectSignOut: 'exp://127.0.0.1:19000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    
    }
}


// identityPoolId = Fedeterated Identity -  url
//userPooolWebClientId = app client id of user pool

// const oauth = {
//     // Domain name
//     domain : 'your-domain-prefix.auth.us-east-1.amazoncognito.com', 

//     // Authorized scopes
//     scope : ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'], 

//     // Callback URL
//     redirectSignIn : 'farmingforall://root/', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

//     // Sign out URL
//     redirectSignOut : 'http://www.example.com/signout/', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

//     // 'code' for Authorization code grant, 
//     // 'token' for Implicit grant
//     responseType: 'code', 

//     // optional, for Cognito hosted ui specified options
//     options: {
//         // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
//         AdvancedSecurityDataCollectionFlag : true
//     }
// }


export default awsConfig;