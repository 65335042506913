import Colors from "../../constants/Colors";
import i18n from 'i18n-js';
export default {
    ViewType: {
        Current: 'Current',
        Historical: 'Historical'
    },
    textColorMap: {
        soilMoisture: '#1713DF',
        temperature: '#E019E8',
        humidity: '#E0DA36',
        ph: Colors.tintColor,
        sunlight: '#EAD62D',
        snr: '#AC2DE7',
        rssi: '#02021B'
    },
    gradientColors: ['#11998e', '#38ef7d'],
    alternateGradientColors: ['#11998e', '#C6FFDD'],
    whiteGradientColors: ['#fff', '#fff'],
    textColor: '#fff',
    blackColor: '#000000',
    greyColor: '#696767',
    cardValueFontSize: 20,
    cardLabelFontSize: 12,
    rowAlternateColor: '#C6FFDD',
    lastNDaysHistoricalData: 7,
    maxGraphData: 5,
    expandImage: require(`../../assets/images/app-images/expandbutton.png`),
    collapseImage: require(`../../assets/images/app-images/collapsebutton.png`),
    sensorDataMap: {
        ph: {
            key: 'ph',
            value: (originalValue) => originalValue,
            showInGraph: true,
            graphColor: Colors.tintColor,
            minValue: 0,
            maxValue: 10,
            graphType: 'Bar',
            imageSrc: require(`../../assets/images/app-images/historical/ph.jpg`)
        },
        temperature: {
            key: 'temperature',
            value: (originalValue) => originalValue || originalValue === 0 ? `${originalValue} ${i18n.t('degreeCentigrade')}` : `${i18n.t('na')}`,
            showInGraph: true,
            graphColor: '#E019E8',
            minValue: 0,
            maxValue: 50,
            graphType: 'Line',
            imageSrc: require(`../../assets/images/app-images/historical/temperature.png`)
        },
        humidity: {
            key: 'humidity',
            value: (originalValue) => originalValue || originalValue === 0 ? `${originalValue} %` : `${i18n.t('na')}`,
            showInGraph: false,
            graphColor: '#E019E8',
            minValue: 0,
            maxValue: 100,
            graphType: 'Line',
            imageSrc: require(`../../assets/images/app-images/historical/humidity.png`)
        },
        sunlight: {
            key: 'sunlight',
            value: (originalValue) => originalValue || originalValue === 0 ? `${originalValue} %` : `${i18n.t('na')}`,
            imageSrc: require(`../../assets/images/app-images/historical/sunbulb.png`)
        },
        rssi: {
            key: 'rssi',
            value: (originalValue) => originalValue || originalValue === 0 ? `${originalValue} %` : `${i18n.t('na')}`,
            imageSrc: require(`../../assets/images/app-images/rssi.png`)
        },
        snr: {
            key: 'snr',
            value: (originalValue) => originalValue || originalValue === 0 ? `${originalValue} %` : `${i18n.t('na')}`,
            imageSrc: require(`../../assets/images/app-images/snr.png`)
        },
        soilMoisture: {
            key: 'soilMoisture',
            value: (originalValue) => originalValue || originalValue === 0 ? `${originalValue} %` : `${i18n.t('na')}`,
            showInGraph: true,
            graphColor: '#1713DF',
            minValue: 0,
            maxValue: 100,
            graphType: 'Area',
            imageSrc: require(`../../assets/images/app-images/soil_moisture.png`)
        }
    }
}