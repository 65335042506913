import React from 'react';
import FlexContainer from '../../components/FlexContainer';
import ReadOnlyValue from '../../components/ReadOnlyValue';
import i18n from 'i18n-js';
import { isDesktop } from "../../constants/common";
import Constants from './constants';

const ControllerStateSnapshot = ({
    history, date, totalUpTime, totalDownTime, index
}) => {
    const backgroundColor = index % 2 > 0 ? Constants.rowAlternateColor : ''
    return (
        <FlexContainer padding={0} direction={'column'}>
            <FlexContainer padding={0} direction={isDesktop ? 'row' : 'column'}
                marginTop={5}
                borderTopWidth={1} borderBottomWidth={1} borderLeftWidth={1}
                borderRightWidth={1} borderColor={'black'} borderRadius={5}
                backgroundColor={backgroundColor}>
                    <ReadOnlyValue direction={isDesktop ? 'column' : 'row'} label={i18n.t('date')} value={date}/>
                    <ReadOnlyValue direction={isDesktop ? 'column' : 'row'} label={i18n.t('totalUpTime')} value={`${totalUpTime} ${i18n.t('hrs')}`}/>
                    <ReadOnlyValue direction={isDesktop ? 'column' : 'row'} label={i18n.t('totalDownTime')} value={`${totalDownTime} ${i18n.t('hrs')}`}/>
            </FlexContainer>
            <FlexContainer padding={0} direction={'column'}
                borderBottomWidth={1} borderLeftWidth={1}
                borderRightWidth={1} borderColor={'black'} borderRadius={5}>
                    <FlexContainer paddingLeft={5} paddingRight={5} direction={'row'}
                    borderBottomWidth={1} borderColor={'black'}
                    backgroundColor={backgroundColor}>
                        <ReadOnlyValue direction={'row'} label={i18n.t('startTime')}/>
                        <ReadOnlyValue direction={'row'} label={i18n.t('endTime')}/>
                        <ReadOnlyValue direction={'row'} label={i18n.t('duration')}/>
                    </FlexContainer>
                    {(history ||[]).map((data, index) => {
                        return (
                            <FlexContainer paddingLeft={5} paddingRight={5} direction={'row'} key={index}
                                backgroundColor={backgroundColor}>
                                <ReadOnlyValue direction={'row'} label={data.startTime}/>
                                <ReadOnlyValue direction={'row'} label={data.endTime}/>
                                <ReadOnlyValue direction={'row'} label={data.duration}/>
                            </FlexContainer>
                        );
                    })}
            </FlexContainer>
        </FlexContainer>
    );
};

export default ControllerStateSnapshot;