import React from 'react';
import FlexContainer from '../../components/FlexContainer';
import ReadOnlyValue from '../../components/ReadOnlyValue';
import i18n from 'i18n-js';
import { isDesktop } from "../../constants/common";
import { requestCameraRollPermissionsAsync } from 'expo-image-picker';

const ControllerDaySnapshot = ({
    history, date, totalUpTime, totalDownTime
}) => {
    const gradientColors = ['#11998e', '#38ef7d'];
    const textColor = '#fff';
    return (
        <React.Fragment>
            <FlexContainer padding={0} direction={isDesktop ? 'row' : 'column'}>
                <ReadOnlyValue direction={isDesktop ? 'column' : 'row'} label={i18n.t('date')} value={date}/>
                <ReadOnlyValue direction={isDesktop ? 'column' : 'row'} label={i18n.t('totalUpTime')} value={`${totalUpTime} ${i18n.t('hrs')}`}/>
                <ReadOnlyValue direction={isDesktop ? 'column' : 'row'} label={i18n.t('totalDownTime')} value={`${totalDownTime} ${i18n.t('hrs')}`}/>
            </FlexContainer>
            <FlexContainer paddingLeft={5} paddingRight={5} direction={'row'} gradientColors={gradientColors} >
                <ReadOnlyValue direction={'row'} label={i18n.t('startTime')} labelColor={textColor}/>
                <ReadOnlyValue direction={'row'} label={i18n.t('endTime')} labelColor={textColor}/>
                <ReadOnlyValue direction={'row'} label={i18n.t('duration')} labelColor={textColor}/>
            </FlexContainer>
            {(history ||[]).map((data, index) => {
                return (
                    <FlexContainer paddingLeft={5} paddingRight={5} direction={'row'} key={index} >
                        <ReadOnlyValue direction={'row'} label={data.startTime}/>
                        <ReadOnlyValue direction={'row'} label={data.endTime}/>
                        <ReadOnlyValue direction={'row'} label={data.duration}/>
                    </FlexContainer>
                );
            })}
        </React.Fragment>
    );
};

export default ControllerDaySnapshot;