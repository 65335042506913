import {USER_AWS_BACKEND_URL,CONTROLLER_AWS_BACKEND_URL, BASE_PATH, IMAGE_AWS_BACKEND_URL, DEVICE_OPERATION_AWS_BACKEND_URL,
INVENTORY_AWS_BACKEND_URL} from './aws-api';

const USERS_BASE_PATH = "/users";
const ADDRESS_BASE_PATH = "/address";
const REGISTER_PATH = "/register";
const DEREGISTER_PATH = "/deregister";
const RETRIEVE_PATH = "/retrieve";
const IMAGE_PATH = "/image";
const DEVICE_OPERATION_PATH = "/devicestartstop";
const DEVICE_DATA_PATH = "/currentsensordata";
const DEVICE_DATA_HISTORY_PATH = "/listdatacollectorhistoricaldata";
const INVENTORY_SMART = "/inventorysmart";
const CONTROLLER_SMART = "/controllersmart";
const DEVICE_SMART = "/devicesmart";




export const USERS_API = USER_AWS_BACKEND_URL.concat(BASE_PATH).concat(USERS_BASE_PATH).concat('/');
export const ADDRESS_API = USER_AWS_BACKEND_URL.concat(BASE_PATH).concat(USERS_BASE_PATH).concat(ADDRESS_BASE_PATH);
export const LOCATION_RETRIEVE_API = CONTROLLER_AWS_BACKEND_URL.concat(BASE_PATH).concat(RETRIEVE_PATH).concat('/');
export const LOCATION_REGISTER_API = CONTROLLER_AWS_BACKEND_URL.concat(BASE_PATH).concat(REGISTER_PATH).concat('/');
export const LOCATION_DEREGISTER_API = CONTROLLER_AWS_BACKEND_URL.concat(BASE_PATH).concat(DEREGISTER_PATH).concat('/');
export const IMAGE_API = IMAGE_AWS_BACKEND_URL.concat(BASE_PATH).concat(IMAGE_PATH);

export const DEVICE_OPERATION_API = DEVICE_OPERATION_AWS_BACKEND_URL.concat(BASE_PATH).concat(DEVICE_OPERATION_PATH);
export const DEVICE_DATA_API = DEVICE_OPERATION_AWS_BACKEND_URL.concat(BASE_PATH).concat(DEVICE_DATA_PATH);
export const DEVICE_DATA_HISTORY_API = DEVICE_OPERATION_AWS_BACKEND_URL.concat(BASE_PATH).concat(DEVICE_DATA_HISTORY_PATH);

export const INVENTORY_SMART_API = INVENTORY_AWS_BACKEND_URL.concat(BASE_PATH).concat(INVENTORY_SMART);
export const CONTROLLER_SMART_API = CONTROLLER_AWS_BACKEND_URL.concat(BASE_PATH).concat(CONTROLLER_SMART);
export const DEVICE_SMART_API = DEVICE_OPERATION_AWS_BACKEND_URL.concat(BASE_PATH).concat(DEVICE_SMART);