import { useState, useEffect } from "react";
import { useAuth } from "./useAuth";
import { DEVICE_DATA_API, DEVICE_DATA_HISTORY_API } from '../constants/AWSApiUrlConstant';
import { useRoute } from "@react-navigation/native";
const getServerDateTimeFormat = (dateTime, isEnd) => {
    let month = dateTime.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let date = dateTime.getDate();
    date = date < 10 ? `0${date}` : date;
    return dateTime.getFullYear() + '-' +
        (month) + '-' + date + 'T' +
        (isEnd ? '23:59:59 999' : '00:00:00 000');
};
export const useDashboard = () => {
    const route = useRoute();
    const { isLoading, apiCurrentUser, userToken }  = useAuth();
    const [isWorking, setWorking] = useState(true);
    const [userType, setUserType] = useState(false);
    const [currentCriteria, setCurrentCriteria] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [isCollectorDataFiltering, setCollectorDataFilteringState] = useState(false);
    const [isFetchingMoreCollectorHistory, setFetchingMoreCollectorHistoryState] = useState(false);
    const [collectorDataError, setCollectorDataError] = useState(false);
    const [isControllerDataFiltering, setControllerDataFilteringState] = useState(false);
    const [isFetchingMoreControllerHistory, setFetchingMoreControllerHistoryState] = useState(false);
    const [controllerDataError, setControllerDataError] = useState(false);
    const [currentCollectorData, setCollectorData] = useState('');
    const [currentControllerData, setControllerData] = useState('');
    const [historicalCollectorData, setHistoricalCollectorData] = useState('');
    const [historicalControllerData, setHistoricalControllerData] = useState('');
    const [historicalCollectorLastKey, setHistoricalCollectorLastKey] = useState(undefined);
    const [historicalControllerLastKey, setHistoricalControllerLastKey] = useState(undefined);
    const isHistorical = route.params && route.params.viewType === 'Historical';
    const canFilterCollectorData = (criteria) => {
        return criteria && criteria.location && criteria.controller && criteria.collector;
    };
    const canFilterControllerData = (criteria) => {
        return criteria && criteria.location && criteria.controller;
    };
    const onFilterChange = (criteria, user) => {
        setCurrentCriteria({ ...criteria,
            moreRecords: false
        });
        setCurrentUser(user);
        if (canFilterCollectorData(criteria)) {
            onCollectorFilterChange(criteria, user);
        } else if (canFilterControllerData(criteria, user)) {
            onControllerFilterChange(criteria, user);
        }
    }
    const onControllerFilterChange = async (criteria, user) => {
        setControllerDataFilteringState(!criteria.moreRecords);
        setFetchingMoreControllerHistoryState(criteria.moreRecords);
        setControllerDataError(false);
        const response = await fetch(DEVICE_DATA_API, {
            method:'POST',
            body: JSON.stringify({
                farmName: criteria.location,
                controllerId: criteria.controller,
                fromDate: criteria.fromDate ? getServerDateTimeFormat(criteria.fromDate) : undefined,
                toDate: criteria.toDate ? getServerDateTimeFormat(criteria.toDate, true) : undefined,
                username: user || apiCurrentUser.username,
                lastEvaluatedKey: isHistorical  && criteria.moreRecords ?
                    historicalControllerLastKey : undefined
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'JWT'+ userToken,
            }
        });

        const json = await response.json();
        if (json.errorMessage) {
            setControllerDataError(true);
        } else if (isHistorical) {
            const serverData = json.historicalData;
            const historicalData = criteria.moreRecords ?
                [...historicalControllerData, ...serverData] : serverData;
            setHistoricalControllerData(historicalData);
            setHistoricalControllerLastKey(json.lastEvaluatedKey);
        } else {
            //TODO: Once API is ready change this
            setControllerData({
                date:"2020-10-29",
                totalUpTime:1,
                totalDownTime:23,
                history:[
                    {
                        startTime:`09:30 AM`,
                        endTime:`10:00 AM`,
                        duration:`30 Minutes`,
                        status:"Normal"
                    },
                    {
                        startTime:`06:00 PM`,
                        endTime:`06:30 PM`,
                        duration:`30 Minutes`,
                        status:"Normal"
                    }
                ]
            });
        }
        setControllerDataFilteringState(false);
        setFetchingMoreControllerHistoryState(false);
    };

    const mapSensorData = (json) => {
        return {
            creationDate: json.creationDate + 'Z',
            soilMoisture: parseFloat((json.soilmoisture || 0).toFixed(2)),
            ph: json.ph.toFixed(2),
            temperature: json.temperature.toFixed(2),
            humidity: json.humidity.toFixed(2),
            sunlight: json.ldr,
            rssi: json.rssi,
            snr: json.snr
        }
    };
    const onCollectorFilterChange = async (criteria, user) => {
        setCollectorDataFilteringState(!criteria.moreRecords);
        setFetchingMoreCollectorHistoryState(criteria.moreRecords);
        setCollectorDataError(false);
        const response = await fetch(isHistorical ? DEVICE_DATA_HISTORY_API :
            DEVICE_DATA_API, {
            method:'POST',
            body: JSON.stringify({
                farmName: criteria.location,
                controllerId: criteria.controller,
                dataCollectorId: criteria.collector,
                fromDate: criteria.fromDate ? getServerDateTimeFormat(criteria.fromDate) : undefined,
                toDate: criteria.toDate ? getServerDateTimeFormat(criteria.toDate, true) : undefined,
                username: user || apiCurrentUser.username,
                lastEvaluatedKey: isHistorical && criteria.moreRecords ?
                    historicalCollectorLastKey : undefined,
                dataType: isHistorical ? criteria.dataType : undefined
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'JWT'+ userToken,
            }
        });

        const json = await response.json();
        if (json.errorMessage) {
            setCollectorDataError(true);
        } else if (isHistorical) {
            const serverData = json.historicalData.map(mapSensorData);
            const historicalData = criteria.moreRecords ?
                [...historicalCollectorData, ...serverData] : serverData;
            setHistoricalCollectorData(historicalData);
            setHistoricalCollectorLastKey(json.lastEvaluatedKey);
        } else {
            setCollectorData(mapSensorData(json));
        }
        setCollectorDataFilteringState(false);
        setFetchingMoreCollectorHistoryState(false);
    };

    const onLoadMoreHistory = (historyType) => {
        const changedCriteria = { ...currentCriteria,
            moreRecords: true
        };
        setCurrentCriteria(changedCriteria);
        if (historyType === 'SENSOR') {
            onCollectorFilterChange(changedCriteria, currentUser);
        } else if (historyType === 'IRRIGATION') {
            onControllerFilterChange(changedCriteria, currentUser);
        }
    };

    useEffect(() => {
        if (apiCurrentUser) {
            setWorking(false);
            setUserType(apiCurrentUser.accountData.usertype);
        }
    }, [apiCurrentUser]);

    return {
        isLoading: isLoading || isWorking,
        userType,
        isControllerDataFiltering,
        collectorDataError,
        isCollectorDataFiltering,
        controllerDataError,
        onFilterChange,
        onLoadMoreHistory,
        currentCollectorData,
        currentControllerData,
        historicalCollectorData,
        hasMoreCollectorHistory: historicalCollectorLastKey,
        isFetchingMoreCollectorHistory,
        historicalControllerData,
        hasMoreControllerHistory: historicalControllerLastKey,
        isFetchingMoreControllerHistory
    };
};