import React from 'react';
import FlexContainer from '../../components/FlexContainer';
import ControllerStateSnapshot from './ControllerStateSnapshot';
import ControllerDaySnapshot from './ControllerDaySnapshot';
import Button from '../../components/Button';
import i18n from 'i18n-js';
import {
    ActivityIndicator
} from "react-native";
import Colors from "../../constants/Colors";

const ControllerData = ({
    history, date, totalUpTime, totalDownTime, historyData,
    onLoadMoreHistory, hasMoreHistory, isFetchingMoreHistory
}) => {
    return (
        <FlexContainer padding={0} direction={'column'}>
            {   historyData && historyData.length > 0 ?
                historyData.map((data, index) => {
                    return <ControllerStateSnapshot history={data.history}
                        date={data.date} totalUpTime={data.totalUpTime}
                    totalDownTime={data.totalDownTime} key={data.date} index={index}/>
                }) :
                <ControllerDaySnapshot history={history} date={date} totalUpTime={totalUpTime}
                totalDownTime={totalDownTime}/>
            }
            { hasMoreHistory && !isFetchingMoreHistory ? <Button
                title={i18n.t('viewMore')}
                onPress={() => {
                    if (onLoadMoreHistory) {
                        onLoadMoreHistory('IRRIGATIION')
                    }
                }}
                btnStyle={{marginTop: 10, marginBottom: 10, height: 56 }}
                primary
            /> : isFetchingMoreHistory ?
            <ActivityIndicator size="large" color={Colors.appColor}  />
            : null }
        </FlexContainer>
    );
};

export default ControllerData;